
<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <div>
    <b-card title="Informations utilisateur">
     <!-- form -->
     
      <b-row>
         <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
          <b-form-group
            label="Nom"
            label-for="basicInput"
          >
          <validation-provider
                  #default="{ errors }"
                  name="Nom"
                  rules="required"
                >
            <b-form-input
             required
              id="basicInput"
              v-model="form.name"
               
              placeholder="Nom"
             
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
          <b-form-group
            label="Prénom"
            label-for="basicInput"
          >
          <validation-provider
                  #default="{ errors }"
                  name="Prénom"
                  rules="required"
                >
            <b-form-input
             required
              id="basicInput"
              v-model="form.surname"
               
              placeholder="Prénom"
             
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>
        </b-col>

        
      

        <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
          <b-form-group
            label="Téléphone"
            label-for="basicInput"
          >
         <validation-provider
                  #default="{ errors }"
                  name="Téléphone"
                  rules="required"
                >
            <b-form-input
             required
              id="basicInput"
              v-model="form.phone"
               
              placeholder="Téléphone"
           
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>
        </b-col>
        
      </b-row>
      <b-row>
        <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
        
          <b-form-group
            label="Email"
            label-for="basicInput"
          >
          <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
            <b-form-input
             
              id="basicInput"
              type="email"
              v-model="form.email"
              :state="errors.length > 0 ? false:null"
               
              placeholder="Email"
             
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group> 
        </b-col>
        <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
       
          <b-form-group 
            label="Mot de passe"
            label-for="basicInput"
          >
          <validation-provider
                  #default="{ errors }"
                  name="Mot de passe"
                  rules="required"
                >
            <b-form-input
             required
              id="basicInput"
              type="password"
              v-model="form.password"
               
              placeholder="Password"
             
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>
     </b-col>

      </b-row>
      
     
    </b-card>
  </div>
</b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BButton,BFormInput, BOverlay, BRow, BCol, BFormGroup,BFormFile, BCard, BFormCheckbox, BFormTextarea, BForm} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import store from '@/store/index'
import {APIRequest, Auth} from '@simb2s/senseye-sdk'

export default {
  name: 'UserForm',
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormFile,
    BCard,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    BForm
  },
  data() {
      return {
          currentBase64:null,
          file: null,
          edition:false,
          loading:true,
          validation: true,
          form:{
            name:"",
            surname: "",
            username:"",
            phone:"",
            email:"",
            password: "azerty"
          },
          required
      }
  },
  watch: {
    // whenever question changes, this function will run
    'form.name'(newValue) {
      console.log('form user changed')
        this.$emit('updateUserFormEv',this.form)

    },
    'form.surname'(newValue) {
      console.log('form user changed')
        this.$emit('updateUserFormEv',this.form)

    },
    'form.username'(newValue) {
      console.log('form user changed')
        this.$emit('updateUserFormEv',this.form)

    },
    'form.phone'(newValue) {
      console.log('form user changed')
        this.$emit('updateUserFormEv',this.form)

    },
    'form.email'(newValue) {
      console.log('form user changed')
        this.$emit('updateUserFormEv',this.form)

    },
    'form.password'(newValue) {
      console.log('form user changed')
        this.$emit('updateUserFormEv',this.form)

    },
  },
  methods:{
            
  },
  async mounted(){
      
   
    this.loading=false
  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>