
<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <div>
    <b-card title="Nouveau Scan">
     <!-- form -->
      <validation-observer ref="loginValidation">
        <b-form
          class="auth-user-form mt-2"
          @submit.prevent
        >
    
      <b-row>
         <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
          <b-form-group
            label="Nom du scan"
            label-for="basicInput"
          >
          <validation-provider
                  #default="{ errors }"
                  name="Nom"
                  rules="required"
                >
            <b-form-input
             required
              id="basicInput"
              v-model="form.name"
               
              placeholder="Nom"
             
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>
        </b-col>


        <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Measures JSON"
              label-for="Measures JSON"
            >
      
            <b-form-file
            required
                v-model="measures_file"
                :state="Boolean(measures_file)"
                placeholder="Choisissez ou faites glisser un fichier..."
                drop-placeholder="Déposer ici ..."
                @change="fileMeasuresAdded($event)"
            />
           
            </b-form-group>
          </b-col>


          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="PointCloud TXT"
              label-for="PointCloud TXT"
            >
      
            <b-form-file
            required
                v-model="pointcloud_file"
                :state="Boolean(pointcloud_file)"
                placeholder="Choisissez ou faites glisser un fichier..."
                drop-placeholder="Déposer ici ..."
                @change="filePtCloudAdded($event)"
            />
           
            </b-form-group>
          </b-col>


          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Measure Points TXT"
              label-for="Measure Points TXT"
            >
      
            <b-form-file
            required
                v-model="measurepoints_file"
                :state="Boolean(measurepoints_file)"
                placeholder="Choisissez ou faites glisser un fichier..."
                drop-placeholder="Déposer ici ..."
                @change="fileMeasurePointsAdded($event)"
            />
           
            </b-form-group>
          </b-col>
         </b-row>
         <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Utilisateur"
              label-for="Utilisateur"
            ><br />

          <a @click="newUser=!newUser"><span v-if="newUser">- Choisir un utilisateur existant</span><span v-else>+ Nouvel utilisateur</span></a><br /><br />

          <b-form-select v-if="newUser==false" required v-model="form.user.id" :options="userOptions"></b-form-select>

          
         
          </b-form-group>
        </b-col>

        
      </b-row>
      
      
      <UserForm v-if="newUser" @updateUserFormEv="updateUserForm" />

       <b-button
        @click="createScan"
        >
        Enregistrer
        </b-button>

       
      
      </b-form>
          </validation-observer>
    </b-card>
  </div>
</b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BButton,BFormInput, BOverlay, BRow, BCol, BFormGroup,BFormFile, BCard,BFormSelect, BFormCheckbox, BFormTextarea, BForm} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import store from '@/store/index'
import UserForm from './UserForm.vue'
import {APIRequest, Auth} from '@simb2s/senseye-sdk'

export default {
  name: 'AddScan',
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormFile,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BFormTextarea,
    vSelect,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    BForm,
    UserForm
  },
  data() {
      return {
        userForm:{},
          newUser:false,
          currentBase64:null,
          measurepoints_file: null,
          measures_file: null,
          pointcloud_file: null,
          edition:false,
          loading:true,
          validation: true,
          userOptions:[],
          form:{
            name:"",
            date:"",
            main_user:{id:localStorage.id_user_senseye_auth},
            measurements:null,
            coordinates:null,
            measure_coordinates:null,
            user:{id:null}
            
          },
          required
      }
  },
  
  methods:{
      updateUserForm(form){
        this.userForm=form
      }, 
      fileMeasuresAdded(event)
      {
      

        var reader = new FileReader()
        reader.readAsText(event.target.files[0])
        reader.onload = () => {

        this.measures_file=reader.result

        this.form.measurements=reader.result

        };
      }, fileMeasurePointsAdded(event)
      {
      

        var reader = new FileReader()
        reader.readAsText(event.target.files[0])
        reader.onload = () => {

        this.measurepoints_file=reader.result

        this.form.measure_coordinates=reader.result

        };
      },
      filePtCloudAdded(event)
      {
        var reader = new FileReader()
        reader.readAsText(event.target.files[0])
        reader.onload = () => {

        this.pointcloud_file=reader.result

        this.form.coordinates=reader.result

        };
        
      },
      async getUsers() {
        let users=await Auth.list_users_by_user(localStorage.id_user_senseye_auth)
        this.userOptions=[]
        for(let i=0;i<users.length;i++) {
          let item = { value: users[i].id, text:  users[i].name+' '+ users[i].surname }
          this.userOptions.push(item)
        }
        this.form.user.id=users[0].id

      },

      async createScan()
      {
        this.$refs.loginValidation.validate().then(async success => {
        if (success) {
          this.loading=true
          let maDate = new Date()
    maDate.toLocaleString("fr")
      
         

          let datestringfinal=maDate.getDate()+'/'+(maDate.getMonth()*1+1*1)+'/'+maDate.getFullYear()

        

          this.form.date=datestringfinal

            if(this.newUser==true) {

              let checkuser = await Auth.get_user(this.userForm.email)
                
                if(!checkuser){
            
                  this.userForm.username=this.userForm.email
                  let $register = await Auth.register(this.userForm)
                  if($register && $register.status == 200){
                    let user_data = await Auth.get_user(this.userForm.username)

                    console.log('user created')
                    console.log(user_data)

                    this.form.user.id=user_data.id
                  }
                }
                else{
                  alert('Un compte existe déjà avec cet email')
                  this.loading=false
                  
                }
                
            }
           try{           
              await APIRequest.create('scans',this.form)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Le scan a bien été créé !',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                  this.$router.push({name:'scans'})
                }
           catch(error)
           {
             this.loading=false
             this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Une erreur est survenue.',
                  icon: 'AlertCircleIcon',
                  variant: 'warning',
                },
              })
             
           }

        }
        })
      },
      
     
      
  },
  async mounted(){
   
    await this.getUsers()
    this.loading=false
  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>